import styles from "./bio.module.css";

const Bio = ({ strings }) => {
  return(
    <div className = "container-fluid">
      <div className = "row">
        <div className = "col-12">
          { strings.bio.paragraph1.text }
        </div>
      </div>
    </div>
  )
}

export default Bio