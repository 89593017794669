const tracks = {
  "oxygene": {
    displayFormat: "oxygene",
    src: "https://storage.googleapis.com/we-voyagers/tracks/oxygene_master.mp3",
    author: "We\\Voyagers",
    thumbnail: null,
    displayStyles: ["text-uppercase"],
    icon: "rune-health",
    notifications: ["hifiBandcampDownload"]
  },
  "between us": {
    displayFormat: "between us",
    src: "https://storage.googleapis.com/we-voyagers/tracks/between_us_master.mp3",
    author: "We\\Voyagers",
    thumbnail: null,
    displayStyles: ["text-uppercase"],
    icon: "rune-love",
    notifications: ["hifiBandcampDownload"]
  },
  "aunque": {
    displayFormat: "aunque",
    src: "https://storage.googleapis.com/we-voyagers/tracks/aunque_master.mp3",
    author: "We\\Voyagers",
    thumbnail: null,
    displayStyles: ["text-uppercase"],
    icon: "rune-raidho",
    notifications: ["hifiBandcampDownload"]
  },
  "agujero": {
    displayFormat: "agujero",
    src: "https://storage.googleapis.com/we-voyagers/tracks/agujero_master.mp3",
    author: "We\\Voyagers",
    thumbnail: null,
    displayStyles: ["text-uppercase"],
    icon: "rune-dynamic-power",
    notifications: ["hifiBandcampDownload"]
  },
  "sixty-four": {
    displayFormat: "64",
    src: "https://storage.googleapis.com/we-voyagers/tracks/64_master.mp3",
    author: "We\\Voyagers",
    thumbnail: null,
    displayStyles: [],
    icon: "binary-64",
    notifications: ["hifiBandcampDownload"]
  }
}

export default tracks