const esStrings = {
    langs: {
        "english": "EN",
        "spanish": "ES",
        "portuguese": "BR"
    },
    nav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "compra", "icon": "", "navKey": "merch" },
        nav3: { "label": "idioma", "icon": "fa-solid fa-globe", "navKey": "language" },
        nav4: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" }
    },
    sideNav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "compra", "icon": "", "navKey": "merch" }        
    },
    notifications: {
        loadingTrack: { "label": "Espere por favor...cargando pista...", "icon": "" },
        hifiBandcampDownload: { "label": "Descarga Hi-Fi disponible en Bandcamp", "href": "https://www.bandcamp.com", "svg": "bandcamp" },
        notOnline: { "label": "No estás en línea. Conéctate a Internet." }
    },
    bio: {
        modalCloseBtn: { "label": "cerrar" },
        title: { "label": "el fin de un nuevo comienzo..." },
        paragraph1: { "text": `Todos estamos buscando. We\\Voyagers comenzó como un proyecto de música 
            experimental mientras yo buscaba un nuevo comienzo. Evolucionó hacia una fusión de música 
            electrónica progresiva, percusiva y ambiental. Ahora estoy en el final de mi nuevo comienzo y
            te invito a que me acompañes, mi compañero de viaje. Que la música de We\\Voyagers te 
            acompañe en tu propio camino.`
        }
    },
    share: {
        modalCloseBtn: { "label": "cerrar" },
        title: { "label": "comparte We\\Voyagers" },
        text1: { "text": "Comparte We\\Voyagers en Facebook, Reddit, Telegram, y Twitter." }
    },
    social: {
        hashtag: "#We\\Voyagers",
        url: "https://wevoyagers.com",
        title: "We\\Voyagers - Música electrónica progresiva",
        description: "Reúnenos, mi compañero de viaje, en esta exploración de música electrónica progresiva, percusiva, y ambiente.",
        quote: "We\\Voyagers",
        type: "music.playlist",
        image: "https://storage.googleapis.com/we-voyagers/images/rune_raidho_1_1024x1024_colored_for_social_with_name_2.png"
    }    
}

export default esStrings