import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./share.module.css";

const Share = ({ setIsShareModalVisible }) => {
    const shareIconClickHandler = (e) => {
        setIsShareModalVisible(true);
    }

    return(
        <span>
            <FontAwesomeIcon icon = "fa-solid fa-share" className = { styles.shareIcon } onClick = {(e) => { shareIconClickHandler(e) }}></FontAwesomeIcon>
        </span>
    )
}

export default Share