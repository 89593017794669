import { useEffect, useState } from "react";
import DisplayTracks from "./display-tracks.jsx";
import AudioPlayer from "./audio-player.jsx";

const TracksPlayerContainer = ({ isPlaying, setIsPlaying, strings, trackIndex, setTrackIndex }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        function setStatus() {
          setIsOnline(navigator.onLine);
        }
        window.addEventListener("online", setStatus);
        window.addEventListener("offline", setStatus);
    }, [])

    return(
        <>
            <DisplayTracks
                trackIndex = { trackIndex }
                setTrackIndex = { setTrackIndex }
                isPlaying = { isPlaying }
                setIsPlaying = { setIsPlaying }
                isOnline = { isOnline }>
            </DisplayTracks>
            <AudioPlayer
                trackIndex = { trackIndex }
                strings = { strings }
                isPlaying = { isPlaying }
                setIsPlaying = { setIsPlaying }
                isOnline = { isOnline }>
            </AudioPlayer>        
        </>
    )
}

export default TracksPlayerContainer