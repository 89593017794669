const brStrings = {
    langs: {
        "english": "EN",
        "spanish": "ES",
        "portuguese": "BR"
    },
    nav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "comprar", "icon": "", "navKey": "merch" },
        nav3: { "label": "linguagem", "icon": "fa-solid fa-globe", "navKey": "language" },
        nav4: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" }
    },
    sideNav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "comprar", "icon": "", "navKey": "merch" }        
    },
    notifications: {
        loadingTrack: { "label": "Por favor aguarde...carregando faixa...", "icon": "" },
        hifiBandcampDownload: { "label": "Download Hi-Fi disponível no Bandcamp", "href": "https://www.bandcamp.com", "svg": "bandcamp" },
        notOnline: { "label": "Você não está online. Conecte-se à Internet." }
    },
    bio: {
        modalCloseBtn: { "label": "fechar" },
        title: { "label": "o fim de um novo começo..." },
        paragraph1: { "text": `Estamos todos procurando. We\\Voyagers começamos como um projeto de música 
            experimental enquanto eu buscava por um novo começo. Ele evoluiu para uma fusão de música 
            eletrônica progressiva, percussiva e ambiente. Estou agora no final do meu novo começo e convido você a
            se juntar a mim, meu companheiro viajante. Que a música do We\\Voyagers acompanhe você ao longo 
            do seu próprio caminho.`
        }
    },
    share: {
        modalCloseBtn: { "label": "fechar" },
        title: { "label": "compartilhe We\\Voyagers" },
        text1: { "text": "Compartilhe We\\Voyagers no Facebook, Reddit, Telegram e Twitter." }
    },
    social: {
        hashtag: "#We\\Voyagers",
        url: "https://wevoyagers.com",
        title: "We\\Voyagers - Música eletrônica progressiva",
        description: "Junte-se a nós, companheiro viajante, nesta exploração da música eletrônica progressiva, percussiva e ambiente.",
        quote: "We\\Voyagers",
        type: "music.playlist",
        image: "https://storage.googleapis.com/we-voyagers/images/rune_raidho_1_1024x1024_colored_for_social_with_name_2.png"
    }       
}

export default brStrings