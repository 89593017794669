import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "./app-context.jsx";
import Controls from "./controls";
import DisplayNotifications from "./display-notifications.jsx";
import styles from "./audio-player.module.css";

const AudioPlayer = ({ trackIndex, strings, isPlaying, setIsPlaying, isOnline }) => {
  // Get app context
  const context = useContext(AppContext);
  const ids = context.ids;
  const tracksOrder = context.tracksOrder;
  const tracks = context.tracks;
  const trackBlink = context.trackBlink;
  const trackFullOpacity = context.trackFullOpacity;
  const trackHighlight = context.trackHighlight;   
  
  // Hooks
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef();
  const progressBarRef = useRef();
  const [notifications, setNotifications] = useState([]);

  // Set the current track
  const currentTrack = tracks[tracksOrder[trackIndex]];

  const togglePlayPause = () => {
    /**
     * The `togglePlayPause` method toggles `isPlaying` state. The audio player control icon is "attached"
     * to this state and toggles between a play and pause icon.
     * 
     * @param undefined
     * @return undefined
     */

    if (isPlaying) {      
      // Remove the `blink` class from the current track specified by `trackIndex`
      // Remove the `full-opacity` class from the current track specified by `trackIndex`
      // Remove the `highlight-track` class from the current track specified by `trackIndex`
      trackBlink("remove", trackIndex);
      trackFullOpacity("remove", trackIndex);
      trackHighlight("remove", trackIndex);
      setIsPlaying(false);
    } else {
      // Add the `blink` class to the current track specified by `trackIndex`
      // Add the `full-opacity` class to the current track specified by `trackIndex`
      // Add the `highlight-track` class to the current track specified by `trackIndex`
      trackBlink("add", trackIndex);
      trackFullOpacity("add", trackIndex);
      trackHighlight("add", trackIndex);
      setIsPlaying(true);
    }
  };
  
  const onEnded = () => {
    /**
     * The `onEnded` method is triggered by `audio` element `onEnded` events. The method:
     * 
     * - Resets the `audioRef` `currentTime` property to `0`
     * - Resets the `progressBarRef` `value` property to `0`
     * - Calls the `togglePlayPause` method which toggles `isPlaying` state; in this case, `isPlaying` will be set to `false`
     * - Removes all "playback" classes for the track that just finished playing
     * 
     * @param undefined
     * @return undefined
     */

    audioRef.current.currentTime = 0;
    progressBarRef.current.value = 0;
    togglePlayPause();

    // Remove the `blink` class from the current track specified by `trackIndex`
    // Remove the `full-opacity` class from the current track specified by `trackIndex`
    // Remove the `highlight-track` class from the current track specified by `trackIndex`
    trackBlink("remove", trackIndex);
    trackFullOpacity("remove", trackIndex);
    trackHighlight("remove", trackIndex);
  };

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    setIsMetadataLoaded(true);
  };

  // const handlePrevious = () => {
  //   if (trackIndex === 0) {
  //       let lastTrackIndex = tracks.length - 1;
  //       setTrackIndex(lastTrackIndex);
  //       setCurrentTrack(tracks[lastTrackIndex]);
  //   } else {
  //       setTrackIndex((prev) => prev - 1);
  //       setCurrentTrack(tracks[trackIndex - 1]);
  //   }
  //   togglePlayPause();    
  // };

  // const handleNext = () => {
  //   if (trackIndex >= tracks.length - 1) {
  //       setTrackIndex(0);
  //       setCurrentTrack(tracks[0]);
  //   } else {
  //       setTrackIndex((prev) => prev + 1);
  //       setCurrentTrack(tracks[trackIndex + 1]);
  //   }
  //   togglePlayPause();
  // };

  /**
   * The progress bar isn't rendered until `canPlay` is `true`. So, `progressBarRef.current.max`
   * can't be set within the `onLoadedMetadata` method because the progress bar may not yet be rendered
   * when `onLoadedMetadata` is called. The `AudioPlayer` component will re-render when `canPlay`
   * changes from `false` to `true`, which means the following `useEffect` will fire again. The effect
   * will check if `canPlay` is `true`, which it is, and can now set `progressBarRef.current.max`
   * since the progress bar has been rendered.
   */
  useEffect(() => {
    if (canPlay && isOnline) {
      const getTrackNotifications = (currentTrack, strings) => {
        /**
         * The `getTrackNotifications` method gets and returns all the notifications for the current track.
         * 
         * @param {object} currentTrack The current track object
         * @param {object} strings The strings object for the user's chosen language
         * @return {array} _trackNotifications The notifications array for the current track
         * @catch {Error} err
         */
        try {
          const _trackNotifications = [];
          const _trackNotificationKeys = currentTrack.notifications;
          let _key;
          
          for (_key of _trackNotificationKeys) {
            _trackNotifications.push(strings.notifications[_key]);
          }
    
          return _trackNotifications;
        } catch (err) {
          console.error(err);
        }
      }

      progressBarRef.current.max = duration;
      setNotifications(getTrackNotifications(currentTrack, strings));
    }
  }, [canPlay, isOnline, duration, currentTrack, isMetadataLoaded, setNotifications, strings]);

  if (isOnline) {
    return(
      <div id = { ids.audioPlayerArea.id } className = { [styles.audioPlayer, "container-fluid"].join(" ") }>
        <div className = "row justify-content-center">
          <div className = "col-md-6 text-center">
              <div>
                <span className = { styles.trackTitle }>{ currentTrack.displayFormat }</span>
                <span className = { styles.trackArtist }>&nbsp;~&nbsp;We\Voyagers</span>
              </div>
              <audio className = { styles.audioTag } ref = { audioRef } src = { currentTrack.src } preload = "metadata" onLoadedMetadata = { onLoadedMetadata } onCanPlay = {() => { setCanPlay(true) }} onEnded = { onEnded }/>
              {
                (false === canPlay) ?
                  (<span className = { styles.trackLoading }>{ strings.notifications.loadingTrack.label }</span>) :
                  (<Controls audioRef = { audioRef } progressBarRef = { progressBarRef } duration = { duration } timeProgress = { timeProgress } setTimeProgress = { setTimeProgress } togglePlayPause = { togglePlayPause } isPlaying = { isPlaying }></Controls>)
              }
              {
                (0 < notifications.length ) && notifications.map((notification, i) => { 
                  return(
                    <div className = "container">
                      <div className = "row justify-content-center mt-2">
                        <div className = "col-12">
                          <DisplayNotifications notificationIndex = { i } notification = { notification }></DisplayNotifications> 
                        </div>
                      </div>
                    </div>
                  )
                })
              }
          </div>
        </div>
      </div>
    )
  } else {
    return(
      <div className = "container-fluid">
        <div className = "row justify-content-center text-center">
          <div className = "col-md-6">
            <DisplayNotifications notification = { strings.notifications.notOnline }></DisplayNotifications>
          </div>
        </div>
      </div>
    )
  }
}

export default AudioPlayer