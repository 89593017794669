import { useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./controls.module.css";

const Controls = ({ audioRef, progressBarRef, duration, timeProgress, setTimeProgress, togglePlayPause, isPlaying }) => {    
    // Hooks
    const progressBarAnimationRef = useRef();

    // const skipForward = () => {
    //     audioRef.current.currentTime += 15;
    // };

    // const skipBackward = () => {
    //     audioRef.current.currentTime -= 15;
    // };

    const progressBarAnimationCallback = useCallback(() => {
        /**
         * The `progressBarAnimationCallback` method defines the callback function passed to the 
         * `requestAnimationFrame` method. It animates the track playback progress along the progress
         * bar.
         * 
         * @param undefined
         * @return undefined
         */
        
        // let fontSize = appConfig.fontSize;
        // let progressBarThumbSize = appConfig.progressBarThumbSize;
        // let _progressBarWidth = parseInt(window.getComputedStyle(progressBarRef.current).getPropertyValue("width"));
        // let _thumbWidth = fontSize * progressBarThumbSize; // Font size * thumb width expressed in rem
        // let _thumbPctOffset = Math.ceil(((_thumbWidth / _progressBarWidth) * 100) / 2);
        
        const currentTime = parseInt(audioRef.current.currentTime);
        setTimeProgress(currentTime);
        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
            "--range-progress",
            `${Math.ceil((currentTime / duration) * 100)}%`
        );

        progressBarAnimationRef.current = requestAnimationFrame(progressBarAnimationCallback);
    }, [audioRef, duration, progressBarRef, setTimeProgress]);

    const progressBarChangeHandler = () => {
        /**
         * The `progressBarChangeHandler` method is triggered by progress bar `onChange` events.
         * The method assigns the `audioRef` `currentTime` property to the `progressBarRef` `value`
         * property value.
         * 
         * @param undefined
         * @return undefined  
         */

        audioRef.current.currentTime = progressBarRef.current.value;
    };

    const formatTime = (time) => {
        /**
         * The `formatTime` method formats a time quantity expressed in seconds to `mm:ss`.
         * 
         * @param {int} time Amount of time expressed in seconds
         * @return {string} _ The formatted time quantity
         * @catch {Error} err
         */
        try {
            if (time && !isNaN(time)) {
            const minutes = Math.floor(time / 60);
            const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const seconds = Math.floor(time % 60);
            const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${formatMinutes}:${formatSeconds}`;
            }
            return "00:00";
        } catch (err) {
            console.error(err);
        }
    };    

    useEffect(() => {
        if (audioRef && isPlaying) {
          audioRef.current.play();
          progressBarAnimationRef.current = requestAnimationFrame(progressBarAnimationCallback);
        } else {
          audioRef.current.pause();
        }
    }, [isPlaying, audioRef, progressBarAnimationCallback]);

    return(
        <div className = "container-fluid">
            <div className = { [styles.controlElements, "row", "justify-content-center", "align-content-center", "mt-2"].join(" ") }>
                <div className =  "col-12">
                    { isPlaying ? <FontAwesomeIcon className = { styles.playPauseButton } onClick = { togglePlayPause } icon = "fa-solid fa-pause"></FontAwesomeIcon> : <FontAwesomeIcon className = { styles.playPauseButton } onClick = { togglePlayPause } icon = "fa-solid fa-play"></FontAwesomeIcon> }
                    <div className = { styles.progressBar }>
                        <input type = "range" ref = { progressBarRef } defaultValue = "0" onChange = { progressBarChangeHandler } />
                    </div>
                    <div className = { styles.progressCounter }>{ `${formatTime(timeProgress)}/${formatTime(duration)}` }</div>
                </div>
            </div>
        </div>
    )
}

export default Controls