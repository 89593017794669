import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { AppContext } from "./app-context";
import styles from "./share.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacebookShareButton, RedditShareButton, TwitterShareButton, TelegramShareButton } from "next-share";
// import {
//   FacebookShareButton,
//   FacebookIcon,
//   RedditShareButton,
//   RedditIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   TelegramShareButton,
//   TelegramIcon
// } from "next-share";

const ShareModal = ({ isShareModalVisible, setIsShareModalVisible, strings }) => {
  // Get app context
  const context = useContext(AppContext);

  const toggleModal = () => {
    setIsShareModalVisible((state) => !state)
  }
  
  return(
    <Modal size = "lg" show = { isShareModalVisible } onHide = {() => { toggleModal() }}>
      <Modal.Header className = { styles.shareModalHeader }>
          <Modal.Title>{ strings.share.title.label }</Modal.Title>
      </Modal.Header>
      <Modal.Body className = { styles.shareModalBody }>
        <div className = "container">
          <div className = "row justify-content-center">
            <div className = "col-12 text-center mb-3">
              { strings.share.text1.text }
            </div>
            <div className = { [styles.socialButtons, "col-md-5", "text-center"].join(" ") }>
              <FacebookShareButton
                url = { `${ strings.social.url }` }
                quote = { `${ strings.social.quote }` }
                hashtag = { `${ strings.social.hashtag }` }
                image = { `${ strings.social.image }`}>
                <FontAwesomeIcon icon = "fa-brands fa-facebook" className = { styles.platformIcon }></FontAwesomeIcon>
                {/*
                  <FacebookIcon size = { iconSize } round />
                */}
              </FacebookShareButton>
              <RedditShareButton
                url = { `${ strings.social.url }` }
                title = { `${ strings.social.title }` }
                image = { `${ strings.social.image }`}>
                <FontAwesomeIcon icon = "fa-brands fa-reddit" className = { styles.platformIcon }></FontAwesomeIcon>  
                {/* 
                  <RedditIcon size = { iconSize } round />
                */}
              </RedditShareButton>
              <TelegramShareButton
                url = { `${ strings.social.url }` }
                title = { `${ strings.social.title }` }
                hashtag = { `${ strings.social.hashtag }` }
                image = { `${ strings.social.image }`}>
                <FontAwesomeIcon icon = "fa-brands fa-telegram" className = { styles.platformIcon }></FontAwesomeIcon>
                {/*
                  <TelegramIcon size = { iconSize } round />
                */}
              </TelegramShareButton>
              <TwitterShareButton
                url = { `${ strings.social.url }` }
                title = { `${ strings.social.title }` }                                
                hashtag = { `${ strings.social.hashtag }` }
                image = { `${ strings.social.image }`}>
                <FontAwesomeIcon icon = "fa-brands fa-twitter" className = { styles.platformIcon }></FontAwesomeIcon>
                {/*
                  <TwitterIcon size = { iconSize } round />
                */}
              </TwitterShareButton>   
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
          <div className = "d-grid gap-2 mt-3">
              <button type = "submit" className = { [styles.shareModalClose, "btn", "btn-outline-secondary", "pt-2", "pb-2"].join(" ") } onClick = {() => { toggleModal() }}>{ strings.bio.modalCloseBtn.label }</button>
          </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ShareModal