import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AppContext } from "./app-context.jsx";
import Header from "./header.jsx";
import SideNav from "./sidenav.jsx";
import TracksPlayerContainer from "./tracks-player-container.jsx";
import BioModal from "./bio-modal.jsx";
import Share from "./share.jsx";
import ShareModal from "./share-modal.jsx";
import { Helmet } from "react-helmet";
import styles from "./app.module.css";
import enStrings from "../strings/en/strings.js";
import esStrings from "../strings/es/strings.js";
import brStrings from "../strings/br/strings.js";
import alienPlanetOne from "../img/alien_planet_1_3584_2048_centered.jpg";

const App = ({appConfig, tracks, tracksOrder, bgColor, bgURL }) => {
  // Constants
  const ids = {
    appArea: { "id": "appArea" },
    navArea: { "id": "navArea" },
    displayTracksArea: { "id": "displayTracksArea" },
    audioPlayerArea: { "id": "audioPlayerArea" }
  }

  // Hooks
  const isMobile = useMediaQuery({ query: `(max-width: ${ appConfig.breakpoint })` });
  const [strings, setStrings] = useState(enStrings);
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const [isSideNavVisible, setIsSideNavVisible] = useState(0);
  const [isBioModalVisible, setIsBioModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(() => {
    return Math.floor(tracksOrder.length/2)
  });

  const switchLanguage = (e) => {
    /**
     * The `switchLanguage` method sets the strings object based on the user selection from the
     * language dropdown menu.
     * 
     * @param {object} e Click event object triggered by user selection from the language dropdown menu
     * @return void
     */

    let selectedLanguage = e.currentTarget.attributes.getNamedItem("data-lang").value;
    switch (selectedLanguage){
      case ("english"):
        setStrings(enStrings);
        break;
      case ("spanish"):
        setStrings(esStrings);
        break;
      case ("portuguese"):
        setStrings(brStrings);
        break;
      default:
        setStrings(enStrings);
        break;
    }
  };

  const trackBlink = (action, trackIndex) => {
    /**
     * The `trackBlink` utility method adds or removes the `blink` class to the hook of the track
     * specified by `trackIndex`.
     * 
     * @param {string} action `add` or `remove`
     * @param {int} trackIndex The index of the track
     * @return undefined
     * @catch {Error} err
     */

    try {
        let tracksAreaElement = document.getElementById(ids.displayTracksArea.id);
        let clickedHookElement = tracksAreaElement.querySelector("li:nth-child(" + (trackIndex + 1) + ") span[data-elem-func='track-hook']");
        
        if ("add" === action) {
            clickedHookElement.classList.add("blink");
        }

        if ("remove" === action) {
            clickedHookElement.classList.remove("blink");
        }

        return
    } catch (err) {
        console.error(err);
    }
  }  

  const trackFullOpacity = (action, trackIndex) => {
    /**
     * The `trackFullOpacity` utility method adds or removes the `full-opacity` class to the track
     * specified by `trackIndex`.
     * 
     * @param {string} action `add` or `remove`
     * @param {int} trackIndex The index of the track
     * @return undefined
     * @catch {Error} err
     */

    try {
        let tracksAreaElement = document.getElementById(ids.displayTracksArea.id);
        let trackElement = tracksAreaElement.querySelector("li:nth-child(" + (trackIndex + 1) + ")");
        
        if ("add" === action) {
            trackElement.classList.add("full-opacity");
        }

        if ("remove" === action) {
            trackElement.classList.remove("full-opacity");
        }

        return
    } catch (err) {
        console.error(err);
    }
  }  

  const trackHighlight = (action, trackIndex) => {
    /**
     * The `trackHighlight` utility method adds or removes the `highlight-track` class to the track
     * specified by `trackIndex`.
     * 
     * @param {string} action `add` or `remove`
     * @param {int} trackIndex The index of the track
     * @return undefined
     * @catch {Error} err
     */

    try {
        let tracksAreaElement = document.getElementById(ids.displayTracksArea.id);
        let trackElement = tracksAreaElement.querySelector("li:nth-child(" + (trackIndex + 1) + ")");
        
        if ("add" === action) {
            trackElement.classList.add("highlight-track");
        }

        if ("remove" === action) {
            trackElement.classList.remove("highlight-track");
        }

        return
    } catch (err) {
        console.error(err);
    }
  }

  useEffect(() => {
    let bgElement = document.getElementById(`${ ids.appArea.id }`);
    if (null !== bgURL) {
      let preloaderImg = document.createElement("img");
      preloaderImg.src = bgURL;
      
      preloaderImg.addEventListener("load", (e) => {
          bgElement.style.backgroundImage = `url(${ bgURL })`;
          preloaderImg = null;
          setIsBackgroundLoaded(true);
      });
    } else {
      bgElement.style.backgroundImage = `url(${ alienPlanetOne })`;
      setIsBackgroundLoaded(true);
    }
  }, [bgURL, ids.appArea.id])

  useEffect(() => {
    if (isBackgroundLoaded) {
      const root = document.getElementById("root");
      let opacity = 0;
      let intervalID = setInterval(() => {
          if (opacity < 1) {
              opacity = opacity + 0.005
              root.style.opacity = opacity;
          } else {
              clearInterval(intervalID);
          }
      }, 15);      
    }
  }, [isBackgroundLoaded])

  return (
    <AppContext.Provider value = {{ appConfig: appConfig, ids: ids, isMobile: isMobile, tracks: tracks, tracksOrder: tracksOrder, bgColor: bgColor, trackBlink: trackBlink, trackFullOpacity: trackFullOpacity, trackHighlight: trackHighlight }}>
      <Helmet>
          <meta http-equiv = "Cache-control" content = "no-cache" />
          <meta charSet = "utf-8" />
          <title>{ `${ strings.social.title }` }</title>
          <meta name = "description" content = { `${ strings.social.description }` }/>
          <link rel = "canonical" href = { `${ strings.social.url }` } />
          <meta name = "og:title" content = { `${ strings.social.title }` } />
          <meta name = "og:description" content = { `${ strings.social.description }` } />
          <meta name = "og:image" content = { `${ strings.social.image }` } />
          <meta name = "og:url" content = { `${ strings.social.url }` } />
          <meta name = "og:type" content = { `${ strings.social.type }` } />
      </Helmet>
      <div id = { ids.appArea.id } className = { [styles.app, "container-fluid"].join(" ") }>
        <SideNav isSideNavVisible = { isSideNavVisible} setIsSideNavVisible = { setIsSideNavVisible } setIsBioModalVisible = { setIsBioModalVisible } strings = { strings }></SideNav>
        <Header setIsSideNavVisible = { setIsSideNavVisible } setIsBioModalVisible = { setIsBioModalVisible } switchLanguage = { switchLanguage } strings = { strings } setIsPlaying = { setIsPlaying } trackIndex = { trackIndex }></Header>
        <div className = { [styles.inner, "row", "align-content-center"].join(" ") }>
          <TracksPlayerContainer isPlaying = { isPlaying } setIsPlaying = { setIsPlaying } strings = { strings } trackIndex = { trackIndex } setTrackIndex = { setTrackIndex }></TracksPlayerContainer>
        </div>
        <Share setIsShareModalVisible = { setIsShareModalVisible }></Share>
      </div>
      <ShareModal isShareModalVisible = { isShareModalVisible } setIsShareModalVisible = { setIsShareModalVisible } strings = { strings }></ShareModal>      
      <BioModal isBioModalVisible = { isBioModalVisible } setIsBioModalVisible = { setIsBioModalVisible } strings = { strings }></BioModal>
    </AppContext.Provider>
  )
}

export default App