import { Modal } from "react-bootstrap";
import Bio from "./bio.jsx";
import styles from "./bio.module.css";

const BioModal = ({ isBioModalVisible, setIsBioModalVisible, strings }) => {
  const toggleModal = () => {
    setIsBioModalVisible((state) => !state)
  }
  
  return(
    <Modal size = "lg" show = { isBioModalVisible } onHide = {() => { toggleModal() }}>
      <Modal.Header className = { styles.bioModalHeader }>
          <Modal.Title>{ strings.bio.title.label }</Modal.Title>
      </Modal.Header>
      <Modal.Body className = { styles.bioModalBody }>
        <Bio strings = { strings }></Bio>
      </Modal.Body>
      <Modal.Footer>
          <div className = "d-grid gap-2 mt-3">
              <button type = "submit" className = { [styles.bioModalClose, "btn", "btn-outline-secondary", "pt-2", "pb-2"].join(" ") } onClick = {() => { toggleModal() }}>{ strings.bio.modalCloseBtn.label }</button>
          </div>
      </Modal.Footer>
    </Modal>
  )
}

export default BioModal