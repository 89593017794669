import DisplayTrackInner from "./display-track-inner.jsx";
import styles from "./display-track.module.css";

const DisplayTrack = ({ index, trackIndex, trackDetails, isMobile, bgColor, translateX, scale, zIndex, opacity, handlers }) => {    
    if (!isMobile) {
        // Desktop track display styling
        // console.log(`displaying track ${trackDetails.displayFormat}`);
        return(
            <li data-track-index = { index } data-track-name = { trackDetails.displayFormat } style = {{ backgroundColor: bgColor }} className = { styles.cardDesktop } onClick = {(e) => { handlers.trackClick(e) }}>
                <DisplayTrackInner trackDetails = { trackDetails }></DisplayTrackInner>
            </li>
        )
    } else {
        if (trackIndex === index ) {
            return(
                <li data-track-index = { index } data-track-name = { trackDetails.displayFormat } style = {{ transform: `translateX(${ translateX[index] }) scale(${ scale[index] })`, zIndex: `${ zIndex[index] }`, opacity: `${ opacity[index] }`, backgroundColor: `${ bgColor }` }} className = { styles.cardMobile } onClick = {(e) => { handlers.trackClick(e) }} onTouchStart = { (e) => handlers.touchStart(e) } onTouchMove = { (e) => handlers.touchMove(e) } onTouchEnd = { (e) => handlers.touchEnd(e) }>
                    <DisplayTrackInner trackDetails = { trackDetails }></DisplayTrackInner>
                </li>
            )
        } else {
            return(
                <li data-track-index = { index } data-track-name = { trackDetails.displayFormat } style = {{ transform: `translateX(${ translateX[index] }) scale(${ scale[index] })`, zIndex: `${ zIndex[index] }`, opacity: `${ opacity[index] }`, backgroundColor: `${ bgColor }` }} className = { styles.cardMobile } onClick = {(e) => { handlers.trackClick(e) }}>
                    <DisplayTrackInner trackDetails = { trackDetails }></DisplayTrackInner>
                </li>
            )
        }
    }
}

export default DisplayTrack