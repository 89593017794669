import Logo from "./logo.jsx";
import Nav from "./nav.jsx";
import styles from "./header.module.css";

const Header = ({ setIsSideNavVisible, setIsBioModalVisible, switchLanguage, strings, setIsPlaying, trackIndex }) => {
    return(
        <div className = { [styles.header, "container-fluid"].join(" ") }>
            <div className = "row align-content-center pt-1 pb-1">
                <div className = "col">
                    <Logo></Logo>
                </div>
                <div className = "col">
                    <Nav setIsSideNavVisible = { setIsSideNavVisible} setIsBioModalVisible = { setIsBioModalVisible } switchLanguage = { switchLanguage} strings = { strings } setIsPlaying = { setIsPlaying } trackIndex = { trackIndex }></Nav>
                </div>
            </div>
        </div>
    )
}

export default Header