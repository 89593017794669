import DisplayTrackIcon from "./display-track-icon.jsx";
import styles from "./display-track-inner.module.css";

const DisplayTrackInner = ({ trackDetails }) => {
    const buildClassString = (trackStyles) => {
        /**
         * The `buildClassString` method builds a class string based on a track's specified title
         * styles, e.g. `text-uppercase`, `text-capitalize`, etc. This string is assigned to 
         * `className` in the JSX markup for the track title. The track style names are valid 
         * Bootstrap classes for stying text.
         * 
         * @param {array} trackStyles An array of styles to be applied to the track title.
         * @return {string} classString
         * @catch {Error} err
         */

        try {
            let classString = "";
            if (0 < trackStyles.length) {
                let style;
                for (style of trackStyles) {
                    classString += style + " ";
                }
                classString = classString.trimEnd();
            }
            return classString
        } catch (err) {
            console.error(err);
        }
    }
    
    let titleClassString = buildClassString(trackDetails.displayStyles);

    return(
        <div className = { ["container-fluid", styles.trackInnerArea].join(" ") }>
            <div className = { ["row", "justify-content-center", "align-content-center", styles.trackInnerAreaIcon].join(" ") }>
                <div className = "col-12 text-center">
                    {
                        ("" !== trackDetails.icon) && <DisplayTrackIcon icon = { trackDetails.icon }></DisplayTrackIcon>
                    }
                </div>                
            </div>
            <div className = { ["row", "justify-content-center", "align-content-center", styles.trackInnerAreaTitle].join(" ") }>
                <div className = "col-12 text-center">
                    <span className = { titleClassString }>{ trackDetails.displayFormat }</span>
                </div>                
            </div>
            <div className = { ["row", "justify-content-center", "align-content-center", styles.trackInnerAreaHook].join(" ") }>
                <div className = "col-12 text-center">
                    <span data-elem-func = "track-hook" className = { [styles.trackHook, "mb-1"].join(" ") }></span>
                </div>                
            </div>             
        </div>
    )
}

export default DisplayTrackInner