import { useContext, useState } from "react";
import { AppContext } from "./app-context.jsx";
import DisplayTrack from "./display-track.jsx";
import styles from "./display-tracks.module.css";

const DisplayTracks = ({ trackIndex, setTrackIndex, isPlaying, setIsPlaying, isOnline }) => {
    // Get app context
    const context = useContext(AppContext);
    const appConfig = context.appConfig;
    const ids = context.ids;
    const isMobile = context.isMobile;
    const tracks = context.tracks;
    const tracksOrder = context.tracksOrder;
    const trackBgColor = context.bgColor;
    // const isPlaying = context.isPlaying;
    const trackBlink = context.trackBlink;
    const trackFullOpacity = context.trackFullOpacity;
    const trackHighlight = context.trackHighlight;

    // console.log(`tracksOrder in display-tracks: ${tracksOrder}`);

    // Hooks
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    
    let translateX, scale, zIndex, opacity;    
    const minSwipeDistance = 50;

    const setMaxTracksOnASide = (trackIndex, numTracks) => {
        /**
         * The `setNumLeftNumRightTracks` method sets the number of tracks to the left and right
         * of the center track in a mobile layout based on the index of the center track. It also returns
         * the larger of the two values.
         * 
         * @param {int} trackIndex An integer specifying the index of the center track
         * @param {int} numTracks The total number of tracks
         * @return {object} { numLeftTracks: numLeftTracks, numRightTracks: numRightTracks, maxTracksOnASide: maxTracksOnASide }
         * @catch {Error} err
         */

        try {
            let numLeftTracks = trackIndex; // The number of left tracks is equal to the center track index
            let numRightTracks = (numTracks - 1) - trackIndex;
            let maxTracksOnASide = Math.max(numLeftTracks, numRightTracks);
            return { maxTracksOnASide: maxTracksOnASide }     
        } catch (err) {
            console.error(err);
        }
    }
    
    const setScaleTransform = (numTracks, appConfig) => {
        /**
         * The `setScaleTransform` method calculates the base amount by which "background" tracks are
         * scaled in a mobile layout.
         * 
         * @param {int} numTracks The number of tracks
         * @param {object} appConfig The app configuration dictionary
         * @return {object} { scaleTransform: scaleTransform }
         * @catch {Error} err
         */

        try {
            let scaleTransform = Number((appConfig.mobileMinScale/numTracks).toFixed(2));
            // console.log( `scaleTransform: ${scaleTransform}` );
            return { scaleTransform: scaleTransform }
        } catch (err) {
            console.error(err);
        }
    }

    const setTrackTransforms = (numTracks, appConfig, trackIndex) => {
        /**
         * The `setTrackTransforms` method sets the translateX, scale, zIndex, and opacity values for each
         * track when in a mobile layout.
         * 
         * @param {int} numTracks The number of tracks
         * @param {object} appConfig An app configuration object with base values for calculating transforms
         * @param {int} trackIndex Integer index of the center track
         * @return {array} [translateX, scale, zIndex, opacity] Returns an array of arrays
         * @catch {Error} err
         */

        try {
            const translateX = Array(numTracks);
            const scale = Array(numTracks);
            const zIndex = Array(numTracks);
            const opacity = Array(numTracks);

            let fontSize = appConfig.fontSize; // Font size in px
            let gutter = appConfig.mobileGutter; // Gutter in rem
            let trackSize = appConfig.trackSize; // Track size in rem
            let windowWidth = window.innerWidth; // Window width in px
            let { maxTracksOnASide } = setMaxTracksOnASide(trackIndex, numTracks);            
            let maxAvailableSideSpace = (windowWidth - (2 * gutter * fontSize) - (trackSize * fontSize))/2; // Max available horizontal space on a single side
            
            // Calculate the x-axis transform amount; this will be the `maxAvailableSideSpace` divided
            let xTransform = Math.floor(maxAvailableSideSpace/maxTracksOnASide);
            let { scaleTransform } = setScaleTransform(numTracks, appConfig);

            let i, j;
            let tX;
            let sL;
            let zI;
            let oP;

            translateX[trackIndex] = "0px";
            scale[trackIndex] = (1).toString();
            zIndex[trackIndex] = (1).toString();
            // opacity[trackIndex] = (0.8).toString();
            opacity[trackIndex] = (1).toString();

            // Iterate over tracks on left and set transform values
            let countL = 1;
            for (i = trackIndex - 1; i > -1; i--) {
                tX = "-" + (xTransform * countL).toString() + "px";
                sL = (1 - (scaleTransform * countL)).toString();
                zI = (1 - countL).toString();
                // oP = (appConfig.mobileBehindOpacity).toString();
                oP = (1).toString();

                translateX[i] = tX;
                scale[i] = sL;
                zIndex[i] = zI;
                opacity[i] = oP

                countL += 1;
            }

            // Iterate over tracks on right and set transform values
            let countR = 1;
            for (j = trackIndex + 1; j < numTracks; j++) {
                tX = (xTransform * countR).toString() + "px";
                sL = (1 - (scaleTransform * countR)).toString();
                zI = (1 - countR).toString();
                // oP = (appConfig.mobileBehindOpacity).toString();
                oP = (1).toString();

                translateX[j] = tX;
                scale[j] = sL;
                zIndex[j] = zI;
                opacity[j] = oP;

                countR += 1;
            }

            // console.group();
            //     console.log(translateX);
            //     console.log(scale);
            //     console.log(zIndex);
            //     console.log(opacity);
            // console.groupEnd();

            return [translateX, scale, zIndex, opacity]
        } catch (err) {
            console.error(err);
        }
    }

    const onTouchStart = (e) => {
        // Remove the `blink` class from the current track specified by `trackIndex`
        // Remove the `full-opacity` class from the current track specified by `trackIndex`
        // Remove the `highlight-track` class from the current track specified by `trackIndex`
        // Set `isPlaying` to `false` to stop any current audio
        trackBlink("remove", trackIndex);
        trackFullOpacity("remove", trackIndex);
        trackHighlight("remove", trackIndex);        
        setIsPlaying(false);

        setTouchEnd(null); // Otherwise the swipe is fired even with common touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) {
            return
        }

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            // console.log(`user swiped left and track index before swipe was ${trackIndex}`);
            if (trackIndex < Object.keys(tracks).length - 1) {
                setTrackIndex(trackIndex + 1);
            }
        }

        if (isRightSwipe) {
            // console.log(`user swiped right and track index before swipe was ${trackIndex}`);
            if (trackIndex > 0) {
                setTrackIndex(trackIndex - 1);
            }
        }
    }

    const trackClickHandlerDesktop = (e) => {
        if (isOnline) {
            // Get the track that was clicked using 'data-track-key' attribute
            let _clickedTrackIndex = parseInt(e.currentTarget.attributes.getNamedItem("data-track-index").value);
            // let _clickedTrackName = e.currentTarget.attributes.getNamedItem("data-track-name").value;

            // console.group();
                // console.log(`trackIndex: ${_clickedTrackIndex}`);
                // console.log(`trackName: ${_clickedTrackName}`);
            // console.groupEnd();

            if (_clickedTrackIndex !== trackIndex) {
                // User clicked on a different track that the one specified by `trackIndex`

                // Update `trackIndex` hook
                setTrackIndex(_clickedTrackIndex);

                // Remove the `blink` class from the current track specified by `trackIndex`
                // Remove the `full-opacity` class from the current track specified by `trackIndex`
                // Remove the `highlight-track` class from the current track specified by `trackIndex`
                // Set `isPlaying` to `false` to stop any current audio
                trackBlink("remove", trackIndex);
                trackFullOpacity("remove", trackIndex);
                trackHighlight("remove", trackIndex);
                setIsPlaying(false);

                // Add the `blink` class on the clicked track
                // Add the `full-opacity` class on the clicked track
                // Add the `highlight-track` class on the clicked track
                // Set `isPlaying` to `true`
                trackBlink("add", _clickedTrackIndex);
                trackFullOpacity("add", _clickedTrackIndex);
                trackHighlight("add", _clickedTrackIndex);
                setIsPlaying(true);
            } else {
                // User clicked on same track as the one specified by `trackIndex`
                if (isPlaying) {
                    // Track is playing; stop it
                    // Remove the `blink` class from the track
                    // Remove the `full-opacity` class from the track
                    // Remove the `highlight-track` class from the track
                    // Set `isPlaying` to `false` to stop current audio
                    trackBlink("remove", trackIndex);
                    trackFullOpacity("remove", trackIndex);
                    trackHighlight("remove", trackIndex);
                    setIsPlaying(false);                
                } else {
                    // Track is not playing; start it
                    // Add the `blink` class to the track
                    // Add the `full-opacity` class to the track
                    // Add the `highlight-track` class to the track
                    // Set `isPlaying` to `true` to start audio
                    trackBlink("add", trackIndex);
                    trackFullOpacity("add", trackIndex);
                    trackHighlight("add", trackIndex);
                    setIsPlaying(true);
                }

                // No need to set updated track index and updated current track for this case
            }
        }
    }

    const trackClickHandlerMobile = (e) => {
        if (isOnline) {
            // Get the track that was clicked using 'data-track-key' attribute
            let _clickedTrackIndex = parseInt(e.currentTarget.attributes.getNamedItem("data-track-index").value);
            // let _clickedTrackName = e.currentTarget.attributes.getNamedItem("data-track-name").value;        
        
            console.group();
                console.log(`clickedTrackIndex: ${_clickedTrackIndex}`);
                // console.log(`clickedTrackName: ${_clickedTrackName}`);
            console.groupEnd();
            
            if (trackIndex === _clickedTrackIndex) {
                // User clicked on the front track
                    
                if (isPlaying) {
                    // Track is playing; stop it
                    // Remove the `blink` class from the track
                    // Remove the `full-opacity` class from the track
                    // Remove the `highlight-track` class from the track
                    // Set `isPlaying` to `false` to stop current audio
                    trackBlink("remove", trackIndex);
                    trackFullOpacity("remove", trackIndex);
                    trackHighlight("remove", trackIndex);
                    setIsPlaying(false);
                } else {
                    // Track is not playing; start it
                    // Add the `blink` class to the track
                    // Set `isPlaying` to `true` to start audio
                    trackBlink("add", trackIndex);
                    trackFullOpacity("add", trackIndex);
                    trackHighlight("add", trackIndex);
                    setIsPlaying(true);
                }  
            } else {
                // User clicked on a behind track
                // Update `trackIndex` hook
                setTrackIndex(_clickedTrackIndex);

                // Remove the `blink` class from the current track specified by `trackIndex`
                // Remove the `full-opacity` class from the current track specified by `trackIndex`
                // Remove the `highlight-track` class from the current track specified by `trackIndex`
                // Set `isPlaying` to `false` to stop any current audio
                trackBlink("remove", trackIndex);
                trackFullOpacity("remove", trackIndex);
                trackHighlight("remove", trackIndex);
                setIsPlaying(false);

                // Add the `blink` class on the clicked track
                // Add the `full-opacity` class on the clicked track
                // Add the `highlight-track` class on the clicked track
                // Set `isPlaying` to `true`
                trackBlink("add", _clickedTrackIndex);
                trackFullOpacity("add", _clickedTrackIndex);
                trackHighlight("add", _clickedTrackIndex);
                setIsPlaying(true);            
            }
        }
    }
    
    if (isMobile) {
        [translateX, scale, zIndex, opacity] = setTrackTransforms(Object.keys(tracks).length, appConfig, trackIndex);
    } else {
        translateX = null;
        scale = null;
        zIndex = null;
        opacity = null;
    } 

    if (isMobile) {
        // Return markup for mobile layout
        return(
            <div id = { ids.displayTracksArea.id } className = "container-fluid">
                <div className = "row justify-content-center">
                    <div className = "col-12">
                        <ul className = { styles.tracksContainerMobile }>
                            {
                                tracksOrder.map((track, i) => {
                                    return(
                                        <DisplayTrack
                                            key = { track }
                                            trackIndex = { trackIndex }
                                            index = { i }
                                            trackDetails = { tracks[track] }
                                            isMobile = { isMobile }
                                            bgColor = { trackBgColor }
                                            translateX = { translateX }
                                            scale = { scale }
                                            zIndex = { zIndex }
                                            opacity = { opacity }
                                            handlers = {{ touchStart: onTouchStart, touchMove: onTouchMove, touchEnd: onTouchEnd, trackClick: trackClickHandlerMobile }}>   
                                        </DisplayTrack>
                                    )
                                })
                            }      
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        // Return markup for desktop layout
        return(
            <div id = { ids.displayTracksArea.id } className = "container-fluid">
                <div className = "row justify-content-center">
                    <div className = "col-12">
                        <ul className = { styles.tracksContainerDesktop }>
                            {
                                tracksOrder.map((track, i) => {
                                    return(
                                        <DisplayTrack
                                            key = { track }
                                            trackIndex = { null }
                                            index = { i }
                                            trackDetails = { tracks[track] }
                                            isMobile = { isMobile }
                                            bgColor = { trackBgColor }
                                            translateX = { translateX }
                                            scale = { scale }
                                            zIndex = { zIndex }
                                            opacity = { opacity }
                                            handlers = {{ trackClick: trackClickHandlerDesktop }}>
                                        </DisplayTrack>
                                    )
                                })
                            }      
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default DisplayTracks