import styles from "./sidenav-option-wrapper.module.css";

const SideNavOptionWrapper = ({ label, navKey, icon, handlers }) => {    
   return(
    <li className = { ["nav-item", "mt-3", "ms-3", "pb-1", "me-3", "text-capitalize", styles.sideNavItem].join(" ") }>
        <span data-nav-key = { navKey } onClick = { (e) => handlers.click(e) }>{ label }</span>
    </li>
   )
}

export default SideNavOptionWrapper