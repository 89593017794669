const DisplayTrackIcon = ({ icon }) => {
    if ("rune-health" === icon) {
        return(
            <svg viewBox="0 0 1024 1024" width="1024" height="1024">
                <g>
                    <path d="M 489.5,-0.5 C 501.167,-0.5 512.833,-0.5 524.5,-0.5C 606.632,82.1321 688.965,164.632 771.5,247C 763.167,255.333 754.833,263.667 746.5,272C 745.833,272.667 745.167,272.667 744.5,272C 671.333,198.833 598.167,125.667 525,52.5C 524.333,111.167 524.333,169.833 525,228.5C 617.637,317.47 709.971,406.803 802,496.5C 806.242,505.766 804.909,514.099 798,521.5C 706.958,615.041 616.124,708.708 525.5,802.5C 524.5,876.164 524.167,949.83 524.5,1023.5C 512.833,1023.5 501.167,1023.5 489.5,1023.5C 489.667,944.166 489.5,864.833 489,785.5C 399.363,699.53 310.029,613.197 221,526.5C 216.758,517.234 218.091,508.901 225,501.5C 313.364,410.804 401.364,319.804 489,228.5C 489.5,152.167 489.667,75.8341 489.5,-0.5 Z M 524.5,279.5 C 603.298,355.13 681.965,430.963 760.5,507C 681.636,588.196 603.136,669.696 525,751.5C 524.5,594.167 524.333,436.834 524.5,279.5 Z M 488.5,281.5 C 489.666,432.333 489.833,583.333 489,734.5C 413.5,661.667 338,588.833 262.5,516C 338.037,437.965 413.37,359.798 488.5,281.5 Z"/>
                </g>
            </svg>
        )
    }
    if ("rune-love" === icon) {
        return(
            <svg viewBox="0 0 1024 1024" width="1024" height="1024">
                <g>
                    <path d="M 489.5,-0.5 C 501.167,-0.5 512.833,-0.5 524.5,-0.5C 524.333,65.8342 524.5,132.168 525,198.5C 612.248,248.874 699.415,299.374 786.5,350C 786.167,350.333 785.833,350.667 785.5,351C 786.167,358.333 786.833,365.667 787.5,373C 708.574,418.267 629.908,463.934 551.5,510C 630.426,555.267 709.092,600.934 787.5,647C 781.5,657 775.5,667 769.5,677C 687.774,630.877 606.274,584.044 525,536.5C 524.5,698.833 524.333,861.166 524.5,1023.5C 512.833,1023.5 501.167,1023.5 489.5,1023.5C 489.667,864.166 489.5,704.833 489,545.5C 411.5,590.333 334,635.167 256.5,680C 255.056,680.781 253.723,680.614 252.5,679.5C 246.96,669.585 241.293,659.752 235.5,650C 316.167,603.333 396.833,556.667 477.5,510C 396.833,463 316.167,416 235.5,369C 241.67,358.678 248.003,348.511 254.5,338.5C 332.796,383.634 410.963,428.967 489,474.5C 489.5,316.167 489.667,157.834 489.5,-0.5 Z M 524.5,240.5 C 594.636,280.736 664.636,321.236 734.5,362C 664.833,402.167 595.167,442.333 525.5,482.5C 524.5,401.836 524.167,321.169 524.5,240.5 Z"/>
                </g>
            </svg>
        )
    }
    if ("binary-64" === icon) {
        return(
            <svg viewBox="0 0 1024 1024" width="1024px" height="1024px">
                <g><path d="M -0.5,-0.5 C 9.5,-0.5 19.5,-0.5 29.5,-0.5C 29.5,340.833 29.5,682.167 29.5,1023.5C 19.5,1023.5 9.5,1023.5 -0.5,1023.5C -0.5,682.167 -0.5,340.833 -0.5,-0.5 Z"/></g>
                <g><path d="M 115.5,-0.5 C 125.5,-0.5 135.5,-0.5 145.5,-0.5C 145.5,340.833 145.5,682.167 145.5,1023.5C 135.5,1023.5 125.5,1023.5 115.5,1023.5C 115.5,682.167 115.5,340.833 115.5,-0.5 Z"/></g>
                <g><path d="M 231.5,-0.5 C 251.5,-0.5 271.5,-0.5 291.5,-0.5C 291.5,340.833 291.5,682.167 291.5,1023.5C 271.5,1023.5 251.5,1023.5 231.5,1023.5C 231.5,682.167 231.5,340.833 231.5,-0.5 Z"/></g>
                <g><path d="M 377.5,-0.5 C 397.5,-0.5 417.5,-0.5 437.5,-0.5C 437.5,340.833 437.5,682.167 437.5,1023.5C 417.5,1023.5 397.5,1023.5 377.5,1023.5C 377.5,682.167 377.5,340.833 377.5,-0.5 Z"/></g>
                <g><path d="M 523.5,-0.5 C 543.5,-0.5 563.5,-0.5 583.5,-0.5C 583.5,340.833 583.5,682.167 583.5,1023.5C 563.5,1023.5 543.5,1023.5 523.5,1023.5C 523.5,682.167 523.5,340.833 523.5,-0.5 Z"/></g>
                <g><path d="M 669.5,-0.5 C 689.5,-0.5 709.5,-0.5 729.5,-0.5C 729.5,340.833 729.5,682.167 729.5,1023.5C 709.5,1023.5 689.5,1023.5 669.5,1023.5C 669.5,682.167 669.5,340.833 669.5,-0.5 Z"/></g>
                <g><path d="M 815.5,-0.5 C 835.5,-0.5 855.5,-0.5 875.5,-0.5C 875.5,340.833 875.5,682.167 875.5,1023.5C 855.5,1023.5 835.5,1023.5 815.5,1023.5C 815.5,682.167 815.5,340.833 815.5,-0.5 Z"/></g>
                <g><path d="M 963.5,-0.5 C 983.5,-0.5 1003.5,-0.5 1023.5,-0.5C 1023.5,340.833 1023.5,682.167 1023.5,1023.5C 1003.5,1023.5 983.5,1023.5 963.5,1023.5C 963.5,682.167 963.5,340.833 963.5,-0.5 Z"/></g>
            </svg>
        )
    }
    if ("rune-dynamic-power" === icon) {
        return(
            <svg viewBox="0 0 1024 1024" width="1024px" height="1024px">
                <g><path d="M 489.5,-0.5 C 501.167,-0.5 512.833,-0.5 524.5,-0.5C 524.167,32.84 524.5,66.1733 525.5,99.5C 566.5,122.667 607.5,145.833 648.5,169C 656.11,175.098 658.61,182.932 656,192.5C 654.6,195.902 652.433,198.736 649.5,201C 608.034,226.302 566.701,251.802 525.5,277.5C 524.333,377.832 524.167,478.165 525,578.5C 665.167,718.667 805.333,858.833 945.5,999C 937.298,1007.04 929.298,1015.2 921.5,1023.5C 920.167,1023.5 918.833,1023.5 917.5,1023.5C 893.368,998.868 869.035,974.368 844.5,950C 843.667,949.833 842.833,949.667 842,949.5C 797.81,959.922 753.644,970.422 709.5,981C 689.667,983.833 681.167,975.333 684,955.5C 697.177,912.647 709.511,869.647 721,826.5C 650.653,755.986 580.153,685.653 509.5,615.5C 373.146,751.188 237.146,887.188 101.5,1023.5C 100.833,1023.5 100.167,1023.5 99.5,1023.5C 91.369,1014.87 83.0357,1006.37 74.5,998C 98.3472,974.32 122.014,950.486 145.5,926.5C 134.512,881.547 123.679,836.547 113,791.5C 109.667,771.168 118.167,762.334 138.5,765C 182.067,778.303 225.733,790.97 269.5,803C 342.667,729.833 415.833,656.667 489,583.5C 489.5,388.834 489.667,194.167 489.5,-0.5 Z M 530.5,146.5 C 553.944,159.439 577.278,172.605 600.5,186C 577.417,200.625 554.251,215.125 531,229.5C 530.5,201.835 530.333,174.169 530.5,146.5 Z M 156.5,810.5 C 182.193,817.841 207.859,825.341 233.5,833C 214.638,852.195 195.472,871.029 176,889.5C 169.31,863.219 162.81,836.886 156.5,810.5 Z M 751.5,859.5 C 770.604,879.105 789.938,898.605 809.5,918C 784.535,924.127 759.535,930.127 734.5,936C 732.741,936.798 731.074,936.631 729.5,935.5C 737.448,910.324 744.782,884.991 751.5,859.5 Z"/></g>
            </svg>
        )
    }
    if ("rune-raidho" === icon) {
        return(
            <svg viewBox="0 0 1024 1024" width="1024px" height="1024px">
                <g><path d="M 383.5,-0.5 C 395.167,-0.5 406.833,-0.5 418.5,-0.5C 418.333,16.8365 418.5,34.1699 419,51.5C 501.5,134 584,216.5 666.5,299C 663.325,300.577 662.991,302.41 665.5,304.5C 610.7,399.796 555.7,494.963 500.5,590C 572.293,713.752 643.96,837.585 715.5,961.5C 705.33,967.752 694.997,973.752 684.5,979.5C 608.803,849.781 533.47,719.781 458.5,589.5C 513.293,495.415 567.96,401.249 622.5,307C 554.667,239.167 486.833,171.333 419,103.5C 418.5,410.166 418.333,716.833 418.5,1023.5C 406.833,1023.5 395.167,1023.5 383.5,1023.5C 383.5,682.167 383.5,340.833 383.5,-0.5 Z"/></g>
            </svg>       
        )
    }
}

export default DisplayTrackIcon