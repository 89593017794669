import styles from "./display-notifications.module.css";

const DisplayNotifications = ({ notificationIndex, notification }) => {
    if (Object.hasOwn(notification, "href") && Object.hasOwn(notification, "svg")) {
        if ("bandcamp" === notification.svg) {
            return(
                <div key = { notificationIndex } className = { styles.notificationArea }>
                    <svg data-svg-platform = "bandcamp" className = { styles.platformIcon } viewBox = "0 0 30 30" width = "240px" height = "240px" fillRule = "evenodd" clipRule = "evenodd">
                        <path d = "M15,3C8.373,3,3,8.373,3,15s5.373,12,12,12s12-5.373,12-12S21.627,3,15,3z M17.333,18.774H8.578l4.089-7.547h8.755L17.333,18.774z" fillRule = "evenodd" clipRule = "evenodd"/>
                    </svg>
                    <a href = { notification.href } target = "_blank" rel = "noreferrer">{ notification.label }</a>
                </div>
            )
        }
    }

    if (Object.hasOwn(notification, "href")) {
        return(
            <div key = { notificationIndex } className = { styles.notificationArea }>
                <a href = { notification.href } target = "_blank" rel = "noreferrer">{ notification.label }</a>
            </div>
        )
    }
    return(
        <div key = { notificationIndex } className = { styles.notificationArea }>
            <span>{ notification.label }</span>
        </div>
    )
}

export default DisplayNotifications