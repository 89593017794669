import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import styles from "./nav-option-wrapper.module.css";

const NavOptionWrapper = ({ handlers, label, icon, navKey, langs }) => {
    if (("side-panel-menu" !== navKey) && ("language" !== navKey) && ("share" !== navKey)) {
        return(
            <li className = "nav-item">
                <button data-nav-key = { navKey } type = "button" className = "btn" onClick = { (e) => handlers.nav.click(e) }>
                    <div className = { styles.navLabel }>{ label }</div>
                </button>
            </li>
        )
    }

    if ("side-panel-menu" === navKey) {
        return(
            <li className = "nav-item">
                <button data-nav-key = { navKey } type = "button" className = "btn" onClick = { (e) => handlers.nav.click(e) }>
                    <div className = "text-center">
                        <FontAwesomeIcon icon = { icon } className = { [styles.navIcon, "fs-3"].join(" ") }></FontAwesomeIcon>
                    </div>
                </button>
            </li>            
        )
    }
    
    if ("language" === navKey) {
        return(
            <Dropdown data-nav-key = { navKey }>
                <Dropdown.Toggle variant = "secondary" className = { styles.langDropdown }>
                    <FontAwesomeIcon icon = { icon } className = "fs-3"></FontAwesomeIcon>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        Object.keys(langs).map((key, i) => {
                            return (
                                <Dropdown.Item key = { `language-${i}` } data-lang = { key } onClick = {(e) => { handlers.lang.click(e) }}>{ langs[key] }</Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default NavOptionWrapper